<template>
  <div>
    <div class="search">
      <div class="title">兑换券列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="兑换券码:">
          <el-input v-model="params.couponCode"></el-input>
        </el-form-item>
        <!-- <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="状态:">
          <el-select v-model="params.status" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="add">添加</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          <el-button type="primary" @click="exprotTable">导出报表</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <!-- <el-table-column type="index" width="50" align="center">
      </el-table-column> -->
      <el-table-column prop="convertId" label="ID" align="center">
      </el-table-column>
      <el-table-column prop="couponName" label="所属优惠券" align="center">
      </el-table-column>
      <el-table-column prop="couponCode" label="兑换码" align="center">
      </el-table-column>

      <el-table-column
        prop="addTime"
        label="添加时间"
        width="200"
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="userName"
        label="兑换人"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="strStatus" label="状态" width="180" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="remove(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="20%"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="数量:">
          <el-input-number
            v-model="addList.count"
            :step="1"
            :max="10000"
            :min="1"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCouponCodeList,
  getCouponCodeListAdd,
  getCouponCodeListDel,
  exprotCouponCode,
} from "../../../api/coupon.js";
export default {
  props: {
    id: [String, Number],
  },
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        couponCode: "",
        status: 0,
        // nickName: "",
        // phone: "",
        // beginTime: "",
        // endTime: "",
      },
      options: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "未兑换",
          value: 1,
        },
        {
          label: "已兑换",
          value: 2,
        },
      ],
      addList: {
        count: 1,
      },
      addRules: {
        count: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async exprotTable() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const  {data}  = await exprotCouponCode({
        couponCode: this.params.couponCode,
        status: this.params.status,
        couponId: Number(this.id),
      });
      let date = +new Date();
      loading.close();
      let url = window.URL.createObjectURL(data); //转换文件流未url
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `万旅网兑换券报表_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
    },
    rowKey(row) {
      return row.userId;
    },
    add() {
      this.addList.count = 1;
      this.dialogVisible = true;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await getCouponCodeList({
        ...this.params,
        couponId: Number(this.id),
      });
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    remove(row) {
      this.$confirm("此操作将删除该兑换券, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await getCouponCodeListDel({
            convertId: row.convertId,
          });
          this.getList();
          if (data.code == 0) {
            this.$message.success("删除成功");
          } else {
            this.$message.warning(data.msg);
          }
        })
        .catch(() => {});
    },
    submit() {
      this.$refs["addRuleFormRef"].validate(async (valid) => {
        if (valid) {
          //   alert("submit!");
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          const { data } = await getCouponCodeListAdd({
            couponId: Number(this.id),
            ...this.addList,
          });
          loading.close();
          if (data.code == 0) {
            this.$message.success("添加成功");
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.warning(data.msg);
          }
        } else {
          console.log("error submit!!");
          this.$message.warning("请完善内容");
          return false;
        }
      });
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>